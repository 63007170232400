import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from "../assets/images/animaeLogo.png"
import classNames from "classnames"
import { useUserContext } from '../provider/UserProvider';
import { useThemeContext } from '../provider/ThemeProvider';
function Header() {
    const { logined, logout } = useUserContext()
    const { showHeader } = useThemeContext()
    const history = useHistory()
    const [recentPath, setRecentPath] = useState(history.location.pathname)
    const [menu, setMenu] = useState(false)
    const urlTo = (url) => {
        if (menu)
            setMenu(!menu)
        history.push(url)
        setRecentPath(url)

    }
    return (
        <header id="header" >
            <div className="inner inner-1200 relative">
                <div className="header-logo">
                    <Link to="/" onClick={
                        () => {
                            if (menu)
                                setMenu(!menu)
                            setRecentPath("/")

                        }
                    }>
                        <img alt="" src={logo} ></img>
                    </Link>
                </div>
                {logined &&
                    <>
                        <div onClick={() => {
                            setMenu(!menu)
                        }} className={classNames("header-menu flex-sb", { active: menu })}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <nav className={classNames("nav-dropmenu", { animation: menu })}>
                            {/* style={{ borderBottom: recentPath === "/gacha_task" && "#457BCD 3px solid" }} */}
                            <ul className="list-unstyled" >
                                {/* <li>
                                    <button className={classNames({ "selected": recentPath === "/gacha_task" })} onClick={() => urlTo("/gacha_task")}>Task</button>

                                </li>

                                <li>
                                    <button className={classNames({ "selected": recentPath === "/device_info" })} onClick={() => urlTo("/device_info")} >Info</button>
                                </li>
                                <li>

                                    <button className={classNames({ "selected": recentPath === "/analysis" })} onClick={() => urlTo("/analysis")} >Analysis</button>
                                </li>
                                <li>
                                    <button className={classNames({ "selected": recentPath === "/config" })} onClick={() => urlTo("/config")} >Config</button>
                                </li> */}
                                <li>
                                    <button onClick={() => {
                                        setMenu(false)
                                        logout()
                                    }} >Logout</button>
                                </li>
                            </ul>
                        </nav>
                    </>}
            </div>

        </header>
    )
}
export default Header;