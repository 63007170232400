import React, { useEffect, useRef, useState } from 'react';
import { getMasterCode, editMasterCodeApi } from '../../utils/apiUtils';

var QRCode = require('qrcode.react');
export default function Config() {
    const [masterCode, setMasterCode] = useState('')
    const [showModal, setShowModal] = useState(false)

    const [editMasterCode, setEditMasterCode] = useState('')


    useEffect(() => {
        let userToken = localStorage.getItem('token');
        getMasterCode(userToken).then(data => {
            console.log('data: ', data);
            setMasterCode(data.value);
            setEditMasterCode(data.value);
        }).catch(e => console.log(e))
    }, [])

    const handleChange = event => {
        setEditMasterCode(event.target.value);
        console.log('event.target.value: ', event.target.value);
    }

    return (
        <div id="container">

            {showModal && <div className={"modal"} onClick={(e) => {
                setShowModal(false)
            }}>
                <div onClick={(e) => {
                    if (!e) var e = window.event;
                    e.cancelBubble = true;
                    if (e.stopPropagation) e.stopPropagation();
                }}
                    className={"modal-content inner-p20  tc"}>
                    <h5 className={"mb20"}>Edit Master code</h5>
                    <ul className={"list-unstyled mb10"}>
                        <input className={"form-control"} type="text" value={editMasterCode} onChange={handleChange} />
                    </ul>
                    <div className="tr">
                        <button onClick={(e) => {
                            setShowModal(false)
                        }} className="mr10 btn txt-red">Cancel</button>
                        <button
                            onClick={(e) => {
                                let userToken = localStorage.getItem('token');
                                editMasterCodeApi(userToken, editMasterCode).then((res) => {
                                    console.log('res: ', res);
                                    if (res.result == 'success') {
                                        setMasterCode(editMasterCode)
                                        setShowModal(false)
                                    }

                                }).catch(() => {
                                    alert("edit fail")
                                    setShowModal(false)
                                })
                            }}
                            className="btn txt-blue">Confirm</button>

                    </div>
                </div>
            </div>}


            <div className="inner inner-350">
                <h4 className="mb20 tc">Device Config</h4>


                <section>
                    <h5 className="section-title">{'Master Code'}</h5>
                    <div className="inner inner-350 bg-white inner-p40 box-shadow">


                        <div className="flex-column ac">


                            <div className="mb20">
                                <QRCode value={masterCode ? masterCode : ''} />
                            </div>

                            <button onClick={() => {
                                setShowModal(true)
                            }} className="btn btn-secondary">Edit</button>


                        </div>
                    </div>

                </section>
            </div>

        </div >)
}