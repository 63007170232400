import axios from "axios"
const queryString = require('query-string');
export const cloudServerDomain = "http://139.162.126.206:5500"
const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

//non build setting
export const loginDomain = development ? "http://localhost:5990/sportlight/users/login" : "/sportlight/users/login"
export const apiDomain = development ? "http://localhost:5990/sportlight/api" : "/sportlight/api"
export const deviceApiDomain = development ? "http://localhost:5990/device" : "/device"
export const localIoDomain = development ? "http://localhost:5990/" : "/"
export const DEMO = false

export const localTestDomain = 'http://localhost:5990/sportlight/api'

// build Setting
// export const deviceApiDomain = "/device"
// export const apiDomain = "/panel/api"
// export const localIoDomain = "/"

export async function fetchGachaRecord() {
    // l = limit, c = track
    return axios.get(apiDomain + "/gacha_record")
        .then(({ data }) => {
            return data
        })
        .catch(e => { throw e })
}

export async function fetchSuccessRateRecord() {
    return axios.get(apiDomain + "/success_rate").then(({ data }) => {
        console.log('data: ', data);
        return data
    })
        .catch(e => { throw e })
}

export async function fetchCountByDayRecord() {
    return axios.get(apiDomain + "/gacha_count_by_day").then(({ data }) => {
        console.log('fetchCountByDayRecord: ', data);
        return data
    })
        .catch(e => { throw e })
}
export async function fetchCountByHourRecord() {
    return axios.get(apiDomain + "/playByhour").then(({ data }) => {
        console.log('fetchCountByHourRecord: ', data);
        return data
    })
        .catch(e => { throw e })
}
export async function fetchCountByWeekdayRecord() {
    return axios.get(apiDomain + "/playByWeekDay").then(({ data }) => {
        console.log('fetchCountByHourRecord: ', data);
        return data
    })
        .catch(e => { throw e })
}

export async function fetchCountByDayAndPlayerRecord() {
    return axios.get(apiDomain + "/gacha_count_by_day_and_player").then(({ data }) => {
        console.log('gacha_count_by_day_and_player: ', data);
        return data
    })
        .catch(e => { throw e })
}


export async function fetchLocalServerStatus() {
    return axios.get(deviceApiDomain + "/status").then(({ data }) => {
        return data
    })
        .catch(e => { throw e })
    // return 'ok'
}

export async function fetchMachine_id() {
    return axios.get(deviceApiDomain + "/machine_id").then(({ data }) => {
        return data
    })
        .catch(e => { throw e })
    // return 'a0fe9fffd22840e8be13fc54869759a4'
}

export async function fetchMachine_ip() {
    return axios.get(deviceApiDomain + "/ip").then(({ data }) => {
        return data
    })
        .catch(e => { throw e })
    // return '192.168.1.2'
}

export async function fetchCloudServerStatus() {
    return axios.get(cloudServerDomain + "/status").then(({ data }) => {
        return data
    })
        .catch(e => { throw e })
}

export async function fetchGachaDuration() {
    return axios.get(apiDomain + "/gacha_duration").then(({ data }) => {
        return data
    })
        .catch(e => { throw e })
}

export async function fetchGachaCount() {
    return axios.get(apiDomain + "/gacha_count").then(({ data }) => {
        return data
    })
        .catch(e => { throw e })
}

export async function fetchTrackList() {
    return axios.get(apiDomain + "/device_tracks").then(({ data }) => {
        return data
    })
        .catch(e => { throw e })
}


export async function getMasterCode(token) {
    return axios.get(apiDomain + `/mastercode?token=${token}`).then(({ data }) => {
        return data
    })
        .catch(e => { throw e })
}

export async function editMasterCodeApi(token, masterCode) {
    return axios.post(apiDomain + `/mastercode`,
        {
            token, masterCode
        }).then(({ data }) => {
            return data
        })
        .catch(e => { throw e })
}

export async function replaceToken(userId, token) {
    console.log('replace')
    return axios.post(loginDomain + "/replace_token", { userId: userId }, {
        headers:
            { Authorization: `Bearer ${token}` }
    }).then(({ data }) => {
        return ({
            userId: data.userId,
            token: data.token,
        })
    })
        .catch(e => { throw e })
}

export async function fetchLockerStatus(id) {
    return axios.get(deviceApiDomain + "/lockStatus/" + id).then(({ data }) => {
        return data
    })
        .catch(e => { throw e })
}

export async function fetchEventLog() {
    return axios.get(apiDomain + "/logFile").then(({ data }) => {
        console.log('data: ', data);
        return data
    })
        .catch(e => { throw e })
}

export async function testMotor(config) {
    console.log('config: ', config);
    return axios.get(deviceApiDomain + "/testMotor", {
        params: {
            track: config.id,
            time: config.testingTime,
            passcode: config.passcode,
            clockwise: config.clockwise,
        }
    }).then(({ data }) => {
        console.log('data: ', data);
        return data
    })
        .catch(e => { throw e })
}