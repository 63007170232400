import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import { localIoDomain } from '../utils/apiUtils'

export const ThemeContext = createContext();

class ThemeProvider extends React.Component {
    state = {
        showHeader: true
    };

    setHeader = (status) => {
        this.setState({ showHeader: false })
    }

    componentDidMount() {

    }

    render() {
        return (
            <ThemeContext.Provider value={{
                showHeader: this.state.showHeader,
                setHeader: this.setHeader
            }} >
                {this.props.children}
            </ThemeContext.Provider>
        )
    }
}

export const useThemeContext = () => useContext(ThemeContext);
export default ThemeProvider;