import React, { useEffect, useRef, useState } from 'react';
import { useUserContext } from '../../provider/UserProvider';

export default function Login() {
    const [error, setError] = useState(false)
    const { login } = useUserContext()
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const checkValid = () => {
        login(email,password).catch(e => {
            setError(true)
        })
    }
    return (
        <div id="container">
            <div className="inner-540 inner inner-p40 tc bg-white box-shadow">

                <h4 className="bold">Login</h4>
                <div className="inner inner-p20 ">
                    {error && <div className="bold txt-secondary tl mb10 font14">Please check your email/password is input correctly.</div>}
                    <input  onChange={(e)=> setEmail(e.target.value)} className="form-control" type="email" placeholder={"Email"}></input>
                    <input  onChange={(e)=> setPassword(e.target.value)} className="form-control" type="password" placeholder={"Password"}></input>
                    <button className="btn btn-secondary mt10" onClick={() => checkValid()}>Submit</button>
                </div>


            </div>
        </div>)
}