import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import webSocket from 'socket.io-client'
import axios from 'axios';
import { localIoDomain } from '../utils/apiUtils'

export const IoContext = createContext();
const mac = ''
class IoProvider extends React.Component {
    state = {
        ws: null,
        gachaing: false,
        deviceStatus: '',
    };

    initWebSocket = () => {
        //對 getMessage 設定監聽，如果 server 有透過 getMessage 傳送訊息，將會在此被捕捉
        this.state.ws.on('connect', message => {
            this.setState({ deviceStatus: "Available" })
        })

        this.state.ws.on('status', message => {
            console.log(message)
            if (message.status === 'start') {
                this.setState({ gachaing: true })
                this.setState({ deviceStatus: message.status })
            }
            else if (message.status === 'finish' || message.status === 'success') {
                this.setState({ gachaing: false })
                this.setState({ deviceStatus: message.status })

            } else if (message.status === 'timeout' || message.status === 'blocked' || message.status === 'error') {
                this.setState({ deviceStatus: message.status })

                setTimeout((() => {
                    this.setState({ gachaing: false })
                    this.setState({ deviceStatus: 'Available' })
                }), 1000);
            }
            else if (message.status === 'disconnected') {
                this.setState({ gachaing: false })
                this.setState({ deviceStatus: 'disconnected' })
            }
            else if (message.status === 'connected') {
                this.setState({ gachaing: false })
                this.setState({ deviceStatus: 'Available' })
            }
        })
    }


    componentDidMount() {
        // console.log('provider componentDidMount');
        // let ws;
        // try {
        //     ws = webSocket(localIoDomain, { query: { "machine_id": mac, 'deviceType': 'webClient' } })
        // } catch (e) {
        //     this.setState({ deviceStatus: "Error" })
        // }
        // this.setState({
        //     ...this.state,
        //     ws: ws
        // }
        //     , () => {
        //         this.initWebSocket()
        //     }
        // )
    }

    render() {
        return (
            <IoContext.Provider value={{
                ws: this.state.ws,
                gachaing: this.state.gachaing,
                deviceStatus: this.state.deviceStatus,
                gacha: (track_id) => {
                    // console.log('track_id', track_id);
                    // let ws = this.state.ws;
                    // console.log('ws: ', ws);
                    // ws.emit('roomMsg', {
                    //     room_id: mac,
                    //     event: 'test',
                    //     msg: {
                    //         track_id: track_id,
                    //         args: ['--track', track_id]
                    //     }
                    // });
                },
            }} >
                {this.props.children}
            </IoContext.Provider>
        )
    }
}

export const useIoContext = () => useContext(IoContext);
export default IoProvider;