import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Line, Doughnut, Chart, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import {
    apiDomain, fetchGachaDuration, fetchGachaRecord, fetchSuccessRateRecord,
    fetchGachaCount, fetchTrackList, fetchCountByDayRecord, fetchCountByHourRecord,
    fetchCountByWeekdayRecord,
    fetchCountByDayAndPlayerRecord
} from '../../utils/apiUtils';
import 'chartjs-plugin-datalabels';
import { useIoContext } from '../../provider/IoProvider';
import { usePrevious } from '../../utils/usePrevious';

var dateFormat = require("dateformat");
Chart.pluginService.register({
    beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx;

            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#000';
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            //Start with a base font of 30px
            ctx.font = "30px " + fontStyle;

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = (chart.innerRadius * 2);

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight);

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
        }
    }
});



const ChartPlaceHoder = () => {
    return (<div className="tc placeholder-loading" style={{ paddingTop: "50%" }}></div>)
}
export default function Analysis() {
    const [gachaRecord, setGachaRecord] = useState(null)
    const chartRef = useRef(null)
    const [trackOptions, setTrackOptions] = useState(null)
    const [selectedTrack, setSelectedTrack] = useState('all')
    const [filterRecord, setFilterRecord] = useState(null);
    const { gachaing } = useIoContext()
    const previousGachaing = usePrevious(gachaing)
    const [gachaCountData, setGachaCountData] = useState({
        labels: null,
        datasets: [
            {
                label: '# of Votes',
                data: null,
                backgroundColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    })
    const [successRateChartData, setSuccessRateChartData] = useState({
        labels: null,
        datasets: [
            {
                label: "success rate",
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: null,

            },
            {
                label: "",
                borderColor: 'red',
                backgroundColor: 'red'
            },

        ]
    });

    const [countByDayChartData, setCountByDayChartData] = useState({
        labels: null,
        datasets: [
            {
                label: "Total Play Count",
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: null,
            },
        ]
    });

    const [countByHourChartData, setCountByHourChartData] = useState({
        labels: null,
        datasets: [
            {
                label: "Hour",
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.7)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: null,
            },
        ]
    });


    const [countByWeekDayChartData, setCountByWeekDayChartData] = useState({
        labels: null,
        datasets: [
            {
                label: "Weekday",
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.7)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: null,
            },
        ]
    });

    const [countByDayAndPlayerChartData, setCountByDayAndPlayerChartData] = useState({
        labels: null,
        datasets: [
            {
                label: "Player 1",
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(255, 99, 132, 1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: null,

            },
            {
                label: "Player 2",
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(54, 162, 235, 1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: null,

            },


        ]
    });

    const [successDurationChartData, setSuccessDurationChartData] = useState({
        labels: null,
        datasets: [
            {
                label: "Duration(s)",
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: null,
            },

        ]

    });
    async function createSuccessRateChart() {
        try {
            var data = await fetchSuccessRateRecord()
            setSuccessRateChartData(successRateChartData => {
                console.log('successRateChartData: ', successRateChartData);
                var _successRateChartData = {
                    ...successRateChartData
                }
                _successRateChartData.labels = data.map((item) => item.date)
                _successRateChartData.datasets[0].data = data.map((item) => item.rate)
                _successRateChartData.datasets[1].label = "average : " + +(_successRateChartData.datasets[0].data.reduce((a, b) => a + b, 0) / data.length).toFixed(1) + "%"
                let average = 0;
                for (let index = 0; index < data.length; index++) {
                    average += data[index].successRate
                }
                //get 35 days data
                // _successRateChartData.datasets[1].data = Array(data.length).fill(average / data.length)
                return _successRateChartData;
            })

        }
        catch (e) {
            throw e
        }
    }

    async function createCountByDayChart() {
        try {
            var data = await fetchCountByDayRecord()
            setCountByDayChartData(countByDayData => {
                console.log('countByDayData: ', countByDayData);
                var _countByDayData = {
                    ...countByDayData
                }
                _countByDayData.labels = data.map((item) => item.date)
                _countByDayData.datasets[0].data = data.map((item) => item.count)
                // _countByDayData.datasets[1].label = "average : "  +(10).toFixed(1) + "%"
                let average = 10;
                // for (let index = 0; index < data.length; index++) {
                //     average += data[index].count
                // }
                //get 35 days data
                // _successRateChartData.datasets[1].data = Array(data.length).fill(average / data.length)
                return _countByDayData;
            })

        }
        catch (e) {
            throw e
        }
    }


    async function createCountByHourChart() {
        try {
            var data = await fetchCountByHourRecord()
            setCountByHourChartData(countByHourData => {
                console.log('countByHourData: ', countByHourData);
                var _countByHourData = {
                    ...countByHourData
                }
                _countByHourData.labels = data.map((item) => item.hour)
                _countByHourData.datasets[0].data = data.map((item) => item.count)
                return _countByHourData;
            })
        }
        catch (e) {
            console.log('e: ', e);
            throw e
        }
    }


    async function createCountByWeekdayChart() {
        try {
            var data = await fetchCountByWeekdayRecord()
            setCountByWeekDayChartData(countByWeekdayData => {
                console.log('countByHourData: ', countByWeekdayData);
                var _countByWeekdayData = {
                    ...countByWeekdayData
                }
                _countByWeekdayData.labels = data.map((item) => item.weekday)
                _countByWeekdayData.datasets[0].data = data.map((item) => item.count)
                return _countByWeekdayData;
            })
        }
        catch (e) {
            console.log('e: ', e);
            throw e
        }
    }


    async function createCountByDayAndplayerChart() {
        try {
            var data = await fetchCountByDayAndPlayerRecord();
            console.log('fetchCountByDayAndPlayerRecord data: ', data);
            var player1Data = data['1']
            var player2Data = data['2']


            setCountByDayAndPlayerChartData(countByDayData => {
                var _countByDayData = {
                    ...countByDayData
                }
                console.log('_countByDayData: ', _countByDayData);
                _countByDayData.labels = player1Data.map((item) => item.date)
                _countByDayData.datasets[0].data = player1Data.map((item) => item.count)
                _countByDayData.datasets[1].data = player2Data.map((item) => item.count)

                // _countByDayData.datasets[1].label = "average : "  +(10).toFixed(1) + "%"
                let average = 10;
                // for (let index = 0; index < data.length; index++) {
                //     average += data[index].count
                // }
                //get 35 days data
                // _successRateChartData.datasets[1].data = Array(data.length).fill(average / data.length)
                return _countByDayData;
            })

        }
        catch (e) {
            throw e
        }
    }


    async function createCountChart() {
        try {
            var data = await fetchGachaCount()

            setGachaCountData(gachaCountData => {
                var _gachaCountData = {
                    ...gachaCountData
                }
                _gachaCountData.labels = data.map((item) => item.player_id)
                _gachaCountData.datasets[0].data = data.map((item) => item.count)

                //get 35 days data
                // _successRateChartData.datasets[1].data = Array(data.length).fill(average / data.length)
                return _gachaCountData;
            })

        }
        catch (e) {
            throw e
        }
    }
    async function createDurationChart() {
        try {
            var data = await fetchGachaDuration()
            console.log('data: ', data);
            setSuccessDurationChartData(successDurationChartData => {
                var _successDurationChartData = {
                    ...successDurationChartData
                }
                _successDurationChartData.labels = data.map((item) => item.time)
                _successDurationChartData.datasets[0].data = data.map((item) => item.duration)

                //get 35 days data
                // _successRateChartData.datasets[1].data = Array(data.length).fill(average / data.length)
                return _successDurationChartData;
            })

        }
        catch (e) {
            throw e
        }
    }
    useEffect(() => {

        fetchAllData()
    }, [])

    useEffect(() => {
        if (gachaRecord) {
            let record = gachaRecord.filter((item, index) => {

                if (selectedTrack === "all")
                    return item
                else if (selectedTrack)
                    return item.track_id === selectedTrack
                else return []
            })
            setFilterRecord(record)
        }

    }, [selectedTrack, gachaRecord])
    useEffect(() => {
        //new gacha record
        if (previousGachaing && !gachaing) {
            fetchAllData()
        }
    }, [gachaing])

    function fetchAllData() {

        Promise.all([
            fetchGachaRecord().then(data => {
                setGachaRecord(data)

            }).catch(e => { }),
            fetchTrackList().then(data => {
                setTrackOptions(data)
            }).catch(e => { }),
            createSuccessRateChart(),
            createCountByDayChart(),
            createCountByHourChart(),
            createCountByWeekdayChart(),
            createCountByDayAndplayerChart(),
            createDurationChart(),
            createCountChart(),
        ]).then().catch(e => {
            console.log('e: ', e);
        });
    }
    return (
        <div id="container">
            <h3 className="mb20 tc">Sportlight game analysis</h3>
            {/* <h3 className="mb40 tc">Analysis</h3> */}

            <div className="inner inner-1200 mb20 flex-box" >


                {/* @count chart */}
                <div className="flex-box-50 mb20" >

                    <div className="inner  flex-box-50-pl ">
                        <h5 className="section-title">Accumulate count (By Player)</h5>
                        <div className="inner-p20 tc bg-white box-shadow relative">
                            {gachaCountData.datasets[0].data ? <Doughnut data={gachaCountData} options={{
                                elements: {
                                    center: {
                                        text: gachaCountData.datasets[0].data.reduce((a, b) => a + b, 0),
                                        color: '#FF6384', // Default is #000000
                                        fontStyle: 'Arial', // Default is Arial
                                        sidePadding: 30, // Defualt is 20 (as a percentage)
                                    }
                                },
                                plugins: {
                                    datalabels: {
                                        display: function (context) {
                                            var index = context.dataIndex;
                                            return context.dataset.data[index] > 0
                                        },
                                        color: 'white',

                                    }
                                }

                            }} /> : <ChartPlaceHoder />}
                        </div>
                    </div>
                </div>




                <div className="flex-box-50 mb20" >
                    <div className="inner flex-box-50-pr" >
                        <h5 className="section-title">Recent 14 days Count</h5>
                        <div ref={chartRef} className="inner-p20 tc bg-white box-shadow ">
                            {countByDayChartData.datasets[0].data ?
                                <Line data={countByDayChartData} options={{
                                    scales: {
                                        xAxes: [{
                                            ticks: {
                                                autoSkip: false,
                                                callback: function (tick, index, array) {
                                                    return ((index + 1) % 3) ? "" : tick;
                                                },
                                            },
                                            gridLines: {
                                                display: false
                                            },
                                        }],
                                        yAxes: [{
                                            ticks: {
                                                stepSize: 20,
                                                // max: 50,
                                                min: 0,
                                            },

                                        }]
                                    },
                                    legend: {
                                        onClick: function (event, legendItem) { },
                                        labels: {
                                            filter: function (item, chart) {
                                                // Logic to remove a particular legend item goes here
                                                return !item.text.includes('success rate');
                                            }
                                        }
                                    },
                                    annotation: {
                                        drawTime: "afterDraw",
                                        annotations: [{
                                            id: 'a-line-1', // optional
                                            type: 'line',
                                            mode: 'horizontal',
                                            scaleID: 'y-axis-0',
                                            borderColor: 'red',
                                            borderWidth: 2,

                                            // onClick: function (e) {
                                            //     // `this` is bound to the annotation element
                                            // }
                                        }]


                                    },
                                    plugins: {
                                        datalabels: {
                                            display: false,

                                        }
                                    }


                                }} /> : <ChartPlaceHoder />}

                        </div>
                    </div>
                </div>


                <div className="flex-box-50 mb20" >
                    <div className="inner flex-box-50-pr" >
                        <h5 className="section-title">Accumulate Count (By Hour)</h5>

                        <div ref={chartRef} className="inner-p20 tc bg-white box-shadow ">
                            {countByHourChartData.datasets[0].data ?
                                <Bar data={countByHourChartData} options={{
                                    scales: {
                                        xAxes: [{
                                            ticks: {
                                                autoSkip: false,
                                                stepSize: 1,

                                            },
                                            gridLines: {
                                                display: false
                                            },
                                        }],
                                        yAxes: [{
                                            ticks: {
                                                stepSize: 20,
                                                // max: 50,
                                                min: 0,
                                            },

                                        }]
                                    },
                                    legend: {
                                        onClick: function (event, legendItem) { },
                                        labels: {
                                            filter: function (item, chart) {
                                                // Logic to remove a particular legend item goes here
                                                return !item.text.includes('success rate');
                                            }
                                        }
                                    },
                                    annotation: {
                                        drawTime: "afterDraw",
                                        annotations: [{
                                            id: 'a-line-1', // optional
                                            type: 'line',
                                            mode: 'horizontal',
                                            scaleID: 'y-axis-0',
                                            borderColor: 'red',
                                            borderWidth: 2,

                                            // onClick: function (e) {
                                            //     // `this` is bound to the annotation element
                                            // }
                                        }]


                                    },
                                    plugins: {
                                        datalabels: {
                                            display: false,

                                        }
                                    }


                                }} /> : <ChartPlaceHoder />}

                        </div>
                    </div>
                </div>


                <div className="flex-box-50 mb20" >
                    <div className="inner flex-box-50-pr" >
                        <h5 className="section-title">Accumulate Count (By Weekday)</h5>
                        <div ref={chartRef} className="inner-p20 tc bg-white box-shadow ">
                            {countByWeekDayChartData.datasets[0].data ?
                                <Bar data={countByWeekDayChartData} options={{
                                    scales: {
                                        xAxes: [{
                                            ticks: {
                                                autoSkip: false,
                                                stepSize: 1,
                                            },
                                            gridLines: {
                                                display: false
                                            },
                                        }],
                                        yAxes: [{
                                            ticks: {
                                                stepSize: 20,
                                                // max: 50,
                                                min: 0,
                                            },

                                        }]
                                    },
                                    legend: {
                                        onClick: function (event, legendItem) { },
                                        labels: {
                                            filter: function (item, chart) {
                                                // Logic to remove a particular legend item goes here
                                                return !item.text.includes('success rate');
                                            }
                                        }
                                    },
                                    annotation: {
                                        drawTime: "afterDraw",
                                        annotations: [{
                                            id: 'a-line-1', // optional
                                            type: 'line',
                                            mode: 'horizontal',
                                            scaleID: 'y-axis-0',
                                            borderColor: 'red',
                                            borderWidth: 2,

                                            // onClick: function (e) {
                                            //     // `this` is bound to the annotation element
                                            // }
                                        }]


                                    },
                                    plugins: {
                                        datalabels: {
                                            display: false,

                                        }
                                    }


                                }} /> : <ChartPlaceHoder />}

                        </div>
                    </div>
                </div>



                {/* 
                <div className="flex-box-50 mb20" >
                    <div className="inner flex-box-50-pr" >
                        <h5 className="section-title">Total play count (By date)</h5>
                        <div ref={chartRef} className="inner-p20 tc bg-white box-shadow ">
                            {countByDayAndPlayerChartData.datasets[0].data ?
                                <Line data={countByDayAndPlayerChartData} options={{
                                    scales: {
                                        xAxes: [{
                                            ticks: {
                                                autoSkip: false,
                                                callback: function (tick, index, array) {
                                                    return ((index + 1) % 3) ? "" : tick;
                                                },
                                            },
                                            gridLines: {
                                                display: false
                                            },
                                        }],
                                        yAxes: [{
                                            ticks: {
                                                stepSize: 10,
                                                // max: 50,
                                                min: 0,
                                            },

                                        }]
                                    },
                                    legend: {
                                        onClick: function (event, legendItem) { },
                                        labels: {
                                            filter: function (item, chart) {
                                                // Logic to remove a particular legend item goes here
                                                return !item.text.includes('success rate');
                                            }
                                        }
                                    },
                                    annotation: {
                                        drawTime: "afterDraw",
                                        annotations: [{
                                            id: 'a-line-1', // optional
                                            type: 'line',
                                            mode: 'horizontal',
                                            scaleID: 'y-axis-0',
                                            borderColor: 'red',
                                            borderWidth: 2,

                                            // onClick: function (e) {
                                            //     // `this` is bound to the annotation element
                                            // }
                                        }]


                                    },
                                    plugins: {
                                        datalabels: {
                                            display: false,

                                        }
                                    }


                                }} /> : <ChartPlaceHoder />}

                        </div>
                    </div>
                </div>
 */}



                <div className="inner w_100 table-container" >
                    <div className="flex-sb mb10">
                        <h5 className="section-title">Accumulate Data Export(csv)</h5>
                    </div>
                    <div class="d-flex p-2 bd-highlight mb10">
                        <a type="button" class="btn btn-primary btn-sm mr10" href="/sportlight/api/exportDailyData">Daily</a>
                        <a type="button" class="btn btn-primary btn-sm mr10" href="/sportlight/api/exportWeekDayData">Weekday</a>
                        <a type="button" class="btn btn-primary btn-sm mr10" href="/sportlight/api/exportHourData">Hourly</a>
                        <a type="button" class="btn btn-primary btn-sm mr10" href="/sportlight/api/exportRawData">Raw Data</a>
                    </div>

                    <div className="flex-sb mb10">
                        <h5 className="section-title">Play History</h5>
                    </div>

                    <div className="inner-p20 tc bg-white box-shadow w_100">
                        <table id="record-table">

                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Player</th>
                                    <th>avg. Speed(km/h)</th>
                                    <th>avg. Power(w)</th>
                                    <th>Distance(m)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterRecord && filterRecord.map((item, index) => {

                                    return (
                                        <tr key={item + index}>
                                            <td>{dateFormat(item.created_at, "yyyy-mm-dd")}<br />
                                                {dateFormat(item.created_at, "HH:MM:ss")}</td>
                                            <td>{item.player_id == '1' ? "2" : "1"}</td>
                                            <td>{item.speed}</td>
                                            <td>{item.power}</td>
                                            <td>{item.distance}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>

                        </table>

                        {(filterRecord && filterRecord.length === 0) && <div className="tc p40">No Record</div>}
                        {!gachaRecord && <div className="placeholder-loading" style={{ paddingTop: "100%" }}></div>}
                    </div>
                </div>


            </div>
        </div>)
}