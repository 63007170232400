import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import webSocket from 'socket.io-client'
import axios from 'axios';
import { DEMO, loginDomain, replaceToken } from "../utils/apiUtils";

export const UserContext = createContext();

class UserProvider extends React.Component {
    state = {
        logined: DEMO,
        isLoading : true
    };

    login = (email, password) => {
        return axios.post(loginDomain, {
            email: email,
            password: password
        }).then(({ data }) => {
            localStorage.setItem('userId', data.userId);
            localStorage.setItem('lastLogin', new Date().getTime());
            localStorage.setItem('token', data.token);
            this.setState({ logined: true })
        }).catch(e => {
            throw e
        })

        // localStorage.setItem()
    }

    logout = () => {
        this.setState({ logined: false })
        localStorage.clear();
    }
    checkLogined = () => {
        
        var now = new Date().getTime()
       
        const userId = localStorage.getItem('userId')
        const token = localStorage.getItem('token')
        const lastLogin = localStorage.getItem('lastLogin')
        if (userId && token && lastLogin) {
           
            if (now - lastLogin > 60 * 60 * 24 * 1000 * 7) {
                replaceToken(userId, token).then(data => {
                    localStorage.setItem('userId', data.userId);
                    localStorage.setItem('lastLogin', new Date().toISOString());
                    localStorage.setItem('token', data.token);
                    this.setState({ logined: true })
                }).catch(e=> {
                    console.log('e: ', e);
                  
                })
            } 
            else {
                this.setState({ logined: true })
            }
        }
        this.setState({isLoading : false})
        
    }
   
    componentDidMount() {
       
    }

    render() {
        return (
            <UserContext.Provider value={{
                logined: this.state.logined,
                login: this.login,
                logout: this.logout,
                isLoading : this.state.isLoading,
                checkLogined : this.checkLogined
            }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

export const useUserContext = () => useContext(UserContext);
export default UserProvider;