import React, { useEffect, useRef, useState } from 'react';
import { getMasterCode, editMasterCodeApi } from '../../utils/apiUtils';
import { fetchTrackList, testMotor } from '../../utils/apiUtils';


var _ = require('lodash');

export default function Testing() {
    const [masterCode, setMasterCode] = useState('')
    const [trackData, setTrackData] = useState('')

    const [showModal, setShowModal] = useState(false)
    const [editMasterCode, setEditMasterCode] = useState('')

    useEffect(() => {
        fetchTrackList().then((data) => {
            console.log('data: ', data);
            for (const t of data) {
                t.testingTime = '500'
            }
            setTrackData(data)
        });
    }, [])

    const handleChange = event => {
        setMasterCode(event.target.value);
    }

    const handleTimeChange = (event, index) => {
        let td = Object.assign([], trackData);
        td[index].testingTime = event.target.value;
        setTrackData(td);
    }

    const handleTestPress = (event, index, clockwise) => {
        console.log('handleTestPress: ',);
        let trackConfig = trackData[index];
        trackConfig['clockwise'] = clockwise ? 'True' : 'False';
        trackConfig['passcode'] = masterCode;

        testMotor(trackConfig).then(res => {
            console.log('res: ', res);
        });

    }


    return (
        <div id="container">
            <div className="inner inner-350">
                <h4 className="mb20 tc">Testing</h4>

                <section>
                    <h4 className="mb20 tc">Passcode</h4>
                </section>

                <section>
                    <input className="w_100" type="text" value={masterCode} onChange={handleChange} />
                </section>

                {trackData && trackData.map((t, index) => (
                    <section>
                        <br />
                        <h6 className="mb20 tc">{'Track_id:' + t.id}</h6>
                        <input className='ml10' type="radio" value="100" checked={t.testingTime == '100'} onChange={(event) => handleTimeChange(event, index)} /> 0.1
                        <input className='ml10' type="radio" value="500" checked={t.testingTime == '500'} onChange={(event) => handleTimeChange(event, index)} /> 0.5
                        <input className='ml10' type="radio" value="1000" checked={t.testingTime == '1000'} onChange={(event) => handleTimeChange(event, index)} /> 1
                        <input className='ml10' type="radio" value="5000" checked={t.testingTime == '5000'} onChange={(event) => handleTimeChange(event, index)} /> 5
                        <input className='ml10' type="radio" value="10000" checked={t.testingTime == '10000'} onChange={(event) => handleTimeChange(event, index)} /> 10
                        <input className='ml10' type="radio" value="20000" checked={t.testingTime == '20000'} onChange={(event) => handleTimeChange(event, index)} /> 20

                        <br />

                        <button className='mr40' type="button" onClick={(event) => handleTestPress(event, index, true)}>Clockwise</button>
                        <button type="button" onClick={(event) => handleTestPress(event, index, false)}>Anti-Clockwise</button>
                        <br />
                        <br />

                    </section>
                ))}

            </div>


        </div >)
}