import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";

import Header from "./views/header";
import "./assets/css/common.css"
import Home from "./components/Home/Home";
import DeviceInformation from "./components/DeviceInformation/DeviceInformation";
import GachaTask from "./components/GachaTask/GachaTask";
import Analysis from "./components/Analysis/Analysis";
import EventLog from "./components/Home/EventLog";
import Config from "./components/Home/Config";
import Testing from "./components/Home/Testing";
import ApiDoc from "./components/Home/ApiDoc";
import Network from "./components/Home/Network";
import GachaButton from "./views/GachaButton";
import Login from "./components/Login/Login";
import { useUserContext } from "./provider/UserProvider";
import Locker from "./components/Home/LockerStatus";
import ErrorPage from "./components/404";


function App() {
  const { logined, checkLogined, isLoading } = useUserContext()
  useEffect(() => {
    checkLogined()
  }, [])
  return (
    //logged
    isLoading ?
      <div></div>
      :
      logined ?
        <Router>
          <Header />
          <Switch>

            <Route exact path="/">
              <Analysis />
            </Route>
{/* 
            <Route exact path="/device_info">
              <DeviceInformation />
            </Route>

            <Route exact path="/gacha_task">
              <GachaTask />
            </Route>

            <Route exact path="/analysis">
              <Analysis />
            </Route>
            <Route exact path="/locker">
              <Locker />
            </Route>

            <Route exact path="/eventlog">
              <EventLog />
            </Route>

            <Route exact path="/config">
              <Config />
            </Route>
            <Route exact path="/testing">
              <Testing />
            </Route>

            <Route exact path="/api_documentation">
              <ApiDoc />
            </Route>

            <Route exact path="/network_setting">
              <Network />
            </Route> */}

            <Route component = {ErrorPage}></Route>

          </Switch>
          {/* <GachaButton /> */}
        </Router>
        :
        //not logged


        <Router forceRefresh>
          <Header />
          <Switch>

            <Route exact path="/">
              <Login />
            </Route>
            <Redirect to="/" />
          </Switch>
        </Router>
  );
}

export default App;